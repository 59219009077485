// 주문서 업로드 모달
import React, { useState, useEffect } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import * as XLSX from "xlsx";
import { encodeId, parseId } from "../util/stockdata.js";

const ModalUpload = ({
	className,
	open,
	onClose,
	modalBoxStyle,
	stockService,
	historyService,
	orderService,
}) => {
	const [fileName, setFileName] = useState("");
	const [uploadSuccess, setUploadSuccess] = useState(false);
	const [orders, setOrders] = useState([]);
	const [totalUnitsById, setTotalUnitsById] = useState({});

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onload = (e) => {
			setFileName(file.name);
			try {
				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, { type: "array" });
				const sheetName = workbook.SheetNames[0];
				if (!sheetName) {
					throw new Error("No sheets found in the Excel file.");
				}

				const worksheet = workbook.Sheets[sheetName];
				if (!worksheet) {
					throw new Error("Selected sheet is empty or invalid.");
				}

				const json = XLSX.utils.sheet_to_json(worksheet);
				if (json.length === 0) {
					throw new Error("No data found in the Excel file.");
				}

				const totalUnits = { ...totalUnitsById };
				const formattedData = json.map((item) => {
					const productRaw = item["상품명"];
					const optionRaw = item["옵션"];

					const product = productRaw.includes("썸머")
						? "썸머"
						: productRaw.includes("구름")
						? "구름"
						: productRaw;
					const option =
						["화이트", "스킨", "블랙", "페일핑크", "스카이블루"].find((opt) =>
							optionRaw.includes(opt)
						) || "";
					// console.log(`Debug: product = ${product}, option = ${option}`); // 디버깅

					const sku = encodeId(item["쇼핑몰"], product, option);
					const quantity = parseInt(item["주문수량"], 10);

					if (totalUnits[sku]) {
						totalUnits[sku] += quantity;
					} else {
						totalUnits[sku] = quantity;
					}

					// 날짜 문자열 (한국 시간 기준)
					const dateString = item["주문일시"];

					// 문자열을 날짜 객체로 변환
					const [datePart, timePart] = dateString.split(" ");
					const [year, month, day] = datePart.split("-").map(Number);
					const [hour, minute, second] = timePart.split(":").map(Number);

					// 한국 시간 기준으로 날짜 객체 생성
					const kstDate = new Date(year, month - 1, day, hour, minute, second);

					return {
						id: item["원셀주문번호"],
						date: kstDate,
						platform: item["쇼핑몰"],
						product,
						option,
						unit: quantity,
						price:
							item["쇼핑몰"] === "에이블리"
								? item["주문금액"]
								: item["실결제금액"],
						sku,
					};
				});
				setOrders(formattedData);
				setTotalUnitsById(totalUnits);
			} catch (error) {
				console.error("Error processing file: ", error);
				alert("잘못된 파일을 선택했습니다: " + error.message);
				return; // Stop further execution
			}
		};
		reader.readAsArrayBuffer(file);
	};

	useEffect(() => {
		try {
			orders.forEach((order) => {
				orderService.postOrders(
					order.id,
					order.sku,
					order.date,
					order.platform,
					order.product,
					order.option,
					order.unit,
					order.price
				);
			});
			Object.entries(totalUnitsById).forEach(([id, unit]) => {
				const { platform, product, option } = parseId(id);
				stockService.minusUnit(id, unit).then(() => {
					setUploadSuccess(true);
				});
				historyService.postHistory(
					product,
					option,
					unit,
					fileName,
					platform,
					"판매"
				);
				setTimeout(() => {
					window.location.reload();
				}, 10000);
			});
		} catch (error) {
			console.error(error);
		}
	}, [totalUnitsById]);

	return (
		<div>
			<Modal className={className} open={open} onClose={onClose}>
				<Box sx={modalBoxStyle}>
					<h3>주문서 업로드</h3>
					<Button variant="contained" component="label">
						파일 선택
						<input
							type="file"
							style={{ display: "none" }}
							accept=".xlsx, .xls"
							onChange={handleFileUpload}
						/>
					</Button>
					<Box
						sx={{
							display: "flex",
							textAlign: "center",
							flexDirection: "column",
						}}
					>
						<Typography color="#e02d93">{fileName}</Typography>
						<br />
						{uploadSuccess ? (
							<Typography color="primary">
								업로드 파일 반영 완료 👍 <br />
								🌀 잠시 후 페이지가 다시 로딩됩니다 🌀
							</Typography>
						) : null}
					</Box>
				</Box>
			</Modal>
		</div>
	);
};

export default ModalUpload;

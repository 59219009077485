import React from 'react';
import { TableRow, TableCell } from '@mui/material';

const HistoryData = ({ history }) => {
    function formatTimestamp(timestamp) {
        const date = new Date(parseInt(timestamp));

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    }

    const formattedDate = formatTimestamp(history.date);

    return (
        <TableRow>
            <TableCell align='center' sx={{ border: 1 }}>
                {formattedDate}
            </TableCell>
            <TableCell align='center' sx={{ border: 1 }}>
                {history.product}
            </TableCell>
            <TableCell align='center' sx={{ border: 1 }}>
                {history.option}
            </TableCell>
            <TableCell align='center' sx={{ border: 1 }}>
                {history.unit}
            </TableCell>
            <TableCell align='center' sx={{ border: 1 }}>
                {history.category}
            </TableCell>
            <TableCell align='center' sx={{ border: 1 }}>
                {history.fromPlatform}
            </TableCell>
            <TableCell align='center' sx={{ border: 1 }}>
                {history.toPlatform}
            </TableCell>
        </TableRow>
    );
};

export default HistoryData;

import React, { useEffect, useState } from 'react';
import {
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { aggregateData } from '../util/stockdata';
import ModalUpload from '../components/ModalUpload';
import ModalStock from '../components/ModalStock';
import ModalStockModify from '../components/ModalStockModify';

const productOrder = ['썸머', '구름'];
const optionOrder = ['화이트', '스킨', '블랙', '페일핑크', '스카이블루'];
const platformOrder = ['스마트스토어', '에이블리', '지그재그', '쿠팡', '톡스토어'];

const StockCurrent = ({ stockService, historyService, orderService }) => {
    const [data, setData] = useState({});
    const [selectedUnit, setSelectedUnit] = useState({});
    const [stockInModal, setStockInModal] = useState(false);
    const [uploadModal, setUploadModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);

    const handleStockInModal = () => setStockInModal(true);
    const closeStockInModal = () => {
        setStockInModal(false);
    };
    const handleUploadModal = () => setUploadModal(true);
    const closeUploadModal = () => {
        setUploadModal(false);
    };
    const handleUpdateModal = (platform, product, option, unit, ids) => {
        setSelectedUnit({ platform, product, option, unit, ids });
        setUpdateModal(true);
    };
    const closeUpdateModal = () => {
        setUpdateModal(false);
    };

    useEffect(() => {
        stockService
            .getStocks()
            .then((stocks) => {
                const aggregatedData = aggregateData(stocks);
                setData(aggregatedData);
            })
            .catch((error) => console.error(error));
    }, [stockService]);

    // test용
    function sumTotalValues(obj) {
        let sum = 0;
        function recurse(subObj) {
            for (const key in subObj) {
                if (subObj.hasOwnProperty(key)) {
                    if (typeof subObj[key] === 'object' && subObj[key] !== null) {
                        recurse(subObj[key]);
                    } else if (key === 'total') {
                        sum += subObj[key];
                    }
                }
            }
        }
        recurse(obj);
        return sum;
    }

    useEffect(() => {
        const totalSum = sumTotalValues(data);
        console.log(totalSum);
    }, [data]);

    // 상품명, 옵션, 플랫폼 정렬
    const sortData = (data) => {
        const sortedData = {};
        productOrder.forEach((product) => {
            if (data[product]) {
                sortedData[product] = {};
                optionOrder.forEach((option) => {
                    if (data[product][option]) {
                        sortedData[product][option] = {
                            platforms: {},
                            total: data[product][option].total,
                        };
                        // 플랫폼 정렬
                        platformOrder.forEach((platform) => {
                            if (data[product][option].platforms[platform]) {
                                sortedData[product][option].platforms[platform] =
                                    data[product][option].platforms[platform];
                            }
                        });
                    }
                });
            }
        });
        return sortedData;
    };

    const modalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        bgcolor: 'background.paper',
        width: 600,
        height: 300,
        boxShadow: 24,
        padding: '20px 40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    };

    const bigModalBoxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #000',
        bgcolor: 'background.paper',
        width: 600,
        height: 400,
        boxShadow: 24,
        padding: '20px 40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    };

    return (
        <div>
            <Typography
                sx={{
                    textAlign: 'center',
                    mb: 3,
                    fontSize: '2rem',
                    fontWeight: '900',
                }}
            >
                재고 현황
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '20px' }}>
                <Button variant='contained' onClick={handleStockInModal}>
                    입고
                </Button>
                <ModalStock
                    className='modal'
                    open={stockInModal}
                    onClose={closeStockInModal}
                    modalBoxStyle={bigModalBoxStyle}
                    stockService={stockService}
                    historyService={historyService}
                    orderService={orderService}
                />
                <Button variant='contained' sx={{ ml: '10px' }} onClick={handleUploadModal}>
                    주문서 업로드
                </Button>
                <ModalUpload
                    className='modal'
                    open={uploadModal}
                    onClose={closeUploadModal}
                    modalBoxStyle={modalBoxStyle}
                    stockService={stockService}
                    historyService={historyService}
                    orderService={orderService}
                />
            </Box>
            <TableContainer component={Paper} sx={{ border: 1 }}>
                <Table aria-label='simple table' sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' sx={{ border: 1 }}>
                                상품명
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                옵션
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                스마트스토어
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                에이블리
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                지그재그
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                쿠팡
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                톡스토어
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                합계
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(sortData(data)).map(([productName, options]) =>
                            Object.entries(options).map(([optionName, { platforms, total }], index) => (
                                <TableRow key={optionName}>
                                    {index === 0 && (
                                        <TableCell
                                            rowSpan={Object.keys(options).length}
                                            align='center'
                                            sx={{ border: 1 }}
                                        >
                                            {productName}
                                        </TableCell>
                                    )}
                                    <TableCell align='center' sx={{ border: 1 }}>
                                        {optionName}
                                    </TableCell>
                                    {platformOrder.map((platform) => (
                                        <TableCell
                                            key={platform}
                                            align='center'
                                            sx={{
                                                border: 1,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                handleUpdateModal(
                                                    platform,
                                                    productName,
                                                    optionName,
                                                    platforms[platform].units,
                                                    platforms[platform].ids
                                                )
                                            }
                                        >
                                            {platforms[platform].units}{' '}
                                        </TableCell>
                                    ))}
                                    <TableCell align='center' sx={{ border: 1 }}>
                                        {total}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {updateModal && (
                <ModalStockModify
                    className='modal'
                    open={updateModal}
                    onClose={closeUpdateModal}
                    modalBoxStyle={modalBoxStyle}
                    data={selectedUnit}
                    stockService={stockService}
                    historyService={historyService}
                />
            )}
        </div>
    );
};

export default StockCurrent;

import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [text, setText] = useState('');

    const onSubmit = (event) => {
        event.preventDefault();
        onLogin(username, password).catch(setError);
    };

    const setError = (error) => {
        setText(error.toString());
    };

    const onChange = (event) => {
        const {
            target: { name, value },
        } = event;
        switch (name) {
            case 'username':
                return setUsername(value);
            case 'password':
                return setPassword(value);
            default:
        }
    };

    return (
        <>
            <form className='auth-form' onSubmit={onSubmit}>
                <TextField
                    label='ID'
                    variant='outlined'
                    name='username'
                    type='text'
                    value={username}
                    onChange={onChange}
                    className='form-input'
                    sx={{ minWidth: '180px' }}
                />
                <TextField
                    label='PASSWORD'
                    variant='outlined'
                    name='password'
                    type='password'
                    value={password}
                    className='form-input'
                    onChange={onChange}
                    sx={{ minWidth: '180px' }}
                />
                <Button
                    size='small'
                    variant='contained'
                    className='form-btn auth-form-btn'
                    type='submit'
                    sx={{
                        minWidth: '80px',
                        minHeight: '43px',
                        ml: '20px',
                    }}
                >
                    LOGIN
                </Button>
                <p>{text}</p>
            </form>
        </>
    );
};

export default Login;

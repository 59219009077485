export default class StockService {
	constructor(http, tokenStorage) {
		this.http = http;
		this.tokenStorage = tokenStorage;
	}

	async getStocks(id) {
		let params = id ? `/${id}` : "";
		return this.http.fetch(`/stocks${params}`, {
			method: "GET",
			headers: this.getHeaders(),
		});
	}

	async getStocksBySku() {
		return this.http.fetch(`/stocks/sku/sku`, {
			method: "GET",
			headers: this.getHeaders(),
		});
	}

	async getStocksBySkuOfNaver() {
		return this.http.fetch(`/stocks/sku/sku/naver`, {
			method: "GET",
			headers: this.getHeaders(),
		});
	}

	// async updateStock(id, unit) {
	//     return this.http.fetch(`/stocks/${id}`, {
	//         method: 'PUT',
	//         headers: this.getHeaders(),
	//         body: JSON.stringify({ unit }),
	//     });
	// }

	async plusUnit(id, unit) {
		return this.http.fetch(`/stocks/plus/${id}`, {
			method: "PUT",
			headers: this.getHeaders(),
			body: JSON.stringify({ unit }),
		});
	}

	async minusUnit(id, unit) {
		return this.http.fetch(`/stocks/minus/${id}`, {
			method: "PUT",
			headers: this.getHeaders(),
			body: JSON.stringify({ unit }),
		});
	}

	getHeaders() {
		const token = this.tokenStorage.getToken();
		return {
			Authorization: `Bearer ${token}`,
		};
	}
}

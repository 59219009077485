export default class OrderService {
	constructor(http, tokenStorage) {
		this.http = http;
		this.tokenStorage = tokenStorage;
	}

	async getOrders(startDate, endDate) {
		let query = `?startDate=${startDate}&endDate=${endDate}`;
		return this.http.fetch(`/orders${query}`, {
			method: "GET",
			headers: this.getHeaders(),
		});
	}

	async getOrdersByPlatform(product, option) {
		let query = `?product=${product}&option=${option}`;
		return this.http.fetch(`/orders/platform${query}`, {
			method: "GET",
			headers: this.getHeaders(),
		});
	}

	async getOrdersBySku(product, option) {
		let query = `?product=${product}&option=${option}`;
		return this.http.fetch(`/orders/sku${query}`, {
			method: "GET",
			headers: this.getHeaders(),
		});
	}

	async getOrdersOf7days() {
		return this.http.fetch(`/orders/7days`, {
			method: "GET",
			headers: this.getHeaders(),
		});
	}

	async getOrdersOf7daysOnNaver() {
		return this.http.fetch(`/orders/7daysOnNaver`, {
			method: "GET",
			headers: this.getHeaders(),
		});
	}

	async postOrders(id, sku, date, platform, product, option, unit, price) {
		return this.http.fetch(`/orders`, {
			method: "POST",
			headers: this.getHeaders(),
			body: JSON.stringify({
				id,
				sku,
				date,
				platform,
				product,
				option,
				unit,
				price,
			}),
		});
	}

	getHeaders() {
		const token = this.tokenStorage.getToken();
		return {
			Authorization: `Bearer ${token}`,
		};
	}
}

import { Switch, Route, useHistory } from 'react-router-dom';
import Header from './components/Header';
import Stock from './pages/Stock';
import History from './pages/History';
import { useAuth } from './context/AuthContext';

function App({ stockService, historyService, orderService }) {
    const history = useHistory();
    const { user, logout } = useAuth();

    const onLogout = () => {
        if (window.confirm('Do you want to log out?')) {
            logout();
            history.push('/');
        }
    };

    return (
        <div className='app'>
            <Header username={user.username} onLogout={onLogout} />
            <Switch>
                (
                <>
                    <Route exact path='/'>
                        <Stock
                            stockService={stockService}
                            historyService={historyService}
                            orderService={orderService}
                        />
                    </Route>
                    <Route exact path='/history'>
                        <History historyService={historyService} />
                    </Route>
                    <Route
                        path='*'
                        element={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100vh',
                                    fontSize: '3rem',
                                }}
                            >
                                잘못된 접근입니다.
                            </div>
                        }
                    />
                </>
                )
            </Switch>
        </div>
    );
}

export default App;

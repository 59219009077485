import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';

const Header = memo(({ username, onLogout }) => {
    const history = useHistory();

    const goMain = () => {
        history.push('/');
    };

    const onClickHistory = () => {
        window.open('/history', '_blank');
    };

    return (
        <header className='header'>
            <div className='logo'>
                <img src='./logo.png' alt='Poola Logo' className='logo-img' onClick={goMain} />
            </div>
            {username && (
                <nav className='menu'>
                    <span className='logo-user'>{username}님, 안녕하세요.</span>
                    <Button size='small' variant='outlined' className='menu-item' onClick={onLogout}>
                        logout
                    </Button>
                    <Button
                        size='small'
                        variant='contained'
                        className='menu-item'
                        sx={{ ml: '10px' }}
                        onClick={onClickHistory}
                    >
                        history
                    </Button>
                </nav>
            )}
        </header>
    );
});

export default Header;

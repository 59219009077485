import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    FormControl,
    TextField,
    Button,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
} from '@mui/material';
import { encodeId } from '../util/stockdata.js';

const ModalStock = ({ className, open, onClose, modalBoxStyle, stockService, historyService, orderService }) => {
    const platformOrder = ['스마트스토어', '에이블리', '지그재그', '쿠팡', '톡스토어'];

    const options = [
        { value: '화이트', label: '화이트' },
        { value: '스킨', label: '스킨' },
        { value: '블랙', label: '블랙' },
        { value: '페일핑크', label: '페일핑크' },
        { value: '스카이블루', label: '스카이블루' },
    ];

    const [product, setProduct] = useState(''); // 선택 상품
    const [option, setOption] = useState(''); // 선택 옵션
    const [unit, setUnit] = useState(''); // 입고 수량 (string)

    const [ids, setIds] = useState({});
    const [sku, setSku] = useState(0); // 누적 상품별 판매개수 (분모)
    const [platformData, setPlatformData] = useState({}); // 플랫폼별 데이터 (분자)

    // 현재 개수
    const [currentStocks, setCurrentStocks] = useState({
        스마트스토어: 0,
        에이블리: 0,
        지그재그: 0,
        쿠팡: 0,
        톡스토어: 0,
    });

    // 추천 개수
    const [recommendations, setRecommendations] = useState({
        스마트스토어: 0,
        에이블리: 0,
        지그재그: 0,
        쿠팡: 0,
        톡스토어: 0,
    });

    // 최종 입고 개수
    const [stockIn, setStockIn] = useState({
        스마트스토어: 0,
        에이블리: 0,
        지그재그: 0,
        쿠팡: 0,
        톡스토어: 0,
    });

    const [encodedStocks, setEncodedStocks] = useState({});

    // 입고 개수 입력 오류
    const [error, setError] = useState(false);

    const filteredOptions = options.filter((option) => {
        if (product === '구름') {
            return option.value !== '페일핑크' && option.value !== '스카이블루';
        }
        return true;
    });

    const handleChangeProduct = (e) => {
        setProduct(e.target.value);
        setOption(''); // 상품이 변경될 때 옵션을 초기화
    };
    const handleChangeOption = (e) => {
        setOption(e.target.value);
    };
    const handleChangeUnit = (e) => {
        setUnit(e.target.value);
    };
    const handleChangeStockIn = (platform, newValue) => {
        const numericValue = parseInt(newValue, 10); // 숫자로 변환
        if (isNaN(numericValue)) {
            console.error('Invalid input: must be a number');
            return;
        }
        // console.log(`${platform} 변경 값: ${numericValue}`);
        setStockIn((prev) => ({
            ...prev,
            [platform]: numericValue, // 숫자 값으로 상태 업데이트
        }));
    };
    const handleStockIn = () => {
        if (!product || !option || !unit) {
            window.alert('모든 항목을 입력하세요');
            return;
        }
        console.log(stockIn);
        const newEncodedStocks = Object.entries(stockIn).reduce((acc, [platformName, count]) => {
            const encodedId = encodeId(platformName, product, option);
            acc[encodedId] = count; // 객체 구조로 누적
            return acc;
        }, {});
        setEncodedStocks(newEncodedStocks); // 객체로 상태 업데이트

        Object.entries(stockIn).forEach(([toPlatform, unit]) => {
            if (unit !== 0) {
                historyService.postHistory(product, option, unit, '입고', null, toPlatform);
            }
        });
    };

    const onCalculateRatio = async () => {
        try {
            const skuStr = await orderService.getOrdersBySku(product, option);
            const parsedSku = parseInt(skuStr, 10);
            if (isNaN(parsedSku)) {
                throw new Error('Invalid SKU data received');
            }
            const platformData = await orderService.getOrdersByPlatform(product, option);
            if (!platformData.length) {
                throw new Error('No platform data received');
            }

            for (const platform of platformOrder) {
                const id = encodeId(platform, product, option);
                const stockInfo = await stockService.getStocks(id);
                if (stockInfo) {
                    setCurrentStocks((prev) => ({
                        ...prev,
                        [platform]: stockInfo.unit,
                    }));
                }
            }

            setSku(parsedSku);
            setPlatformData(platformData);
        } catch (error) {
            console.error('Error in fetching data:', error);
        }
    };

    useEffect(() => {
        if (sku > 0 && platformData.length > 0) {
            const newRecommendations = {};
            platformOrder.forEach((platform) => {
                newRecommendations[platform] = 0; // 기본값
            });

            platformData.forEach(({ platform, totalUnit }) => {
                const sales = parseInt(totalUnit, 10);
                if (!isNaN(sales)) {
                    const recommend = Math.round((sales / sku) * unit);
                    newRecommendations[platform] = recommend;
                }
            });
            setRecommendations(newRecommendations);
            setStockIn(newRecommendations);
        }
    }, [sku, platformData]);

    useEffect(() => {
        const totalStockIn = Object.values(stockIn).reduce((sum, current) => sum + current, 0);
        if (parseInt(unit) !== totalStockIn) {
            setError(true);
        } else {
            setError(false);
        }
    }, [stockIn]);

    useEffect(() => {
        Object.entries(encodedStocks).forEach(([encodedId, count]) => {
            stockService
                .plusUnit(encodedId, count)
                .catch((error) => console.error(error))
                .finally(() => {
                    onClose();
                    window.location.reload();
                });
        });
    }, [encodedStocks]); // 의존성 배열에 encodedStocks 추가

    return (
        <div>
            <Modal className={className} open={open} onClose={onClose}>
                <Box sx={modalBoxStyle}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormControl sx={{ m: 1, minWidth: 100 }} size='small'>
                            <InputLabel id='product'>상품</InputLabel>
                            <Select value={product} label='product' onChange={handleChangeProduct}>
                                <MenuItem value='썸머'>썸머</MenuItem>
                                <MenuItem value='구름'>구름</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 100 }} size='small'>
                            <InputLabel id='option-label'>옵션</InputLabel>
                            <Select labelId='option-label' value={option} label='Option' onChange={handleChangeOption}>
                                {filteredOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            variant='standard'
                            size='small'
                            type='number'
                            sx={{ width: '80px', ml: '20px' }}
                            onChange={handleChangeUnit}
                            InputProps={{
                                inputProps: { min: 0 },
                            }}
                            autoFocus
                        />
                        <strong>개</strong>
                        <Button size='small' variant='contained' sx={{ ml: '20px' }} onClick={onCalculateRatio}>
                            계산!
                        </Button>
                    </Box>
                    <Box>
                        <TableContainer component={Paper} sx={{ border: 1 }}>
                            <Table aria-label='simple table' sx={{ minWidth: 550 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center' sx={{ border: 1 }}>
                                            분류
                                        </TableCell>
                                        {platformOrder.map((platform) => (
                                            <TableCell key={platform} align='center' sx={{ border: 1 }}>
                                                {platform}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component='th' scope='row' align='center' sx={{ border: 1 }}>
                                            현재
                                        </TableCell>
                                        {platformOrder.map((platform) => (
                                            <TableCell key={platform} align='center' sx={{ border: 1 }}>
                                                <Typography sx={{ color: '#878787' }}>
                                                    {currentStocks[platform]}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component='th' scope='row' align='center' sx={{ border: 1 }}>
                                            추천
                                        </TableCell>
                                        {platformOrder.map((platform) => (
                                            <TableCell key={platform} align='center' sx={{ border: 1 }}>
                                                <Typography
                                                    sx={{
                                                        color: 'rgb(255, 0, 0)',
                                                        fontWeight: '900',
                                                    }}
                                                >
                                                    {recommendations[platform]}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component='th' scope='row' align='center' sx={{ border: 1 }}>
                                            입고
                                        </TableCell>
                                        {platformOrder.map((platform) => (
                                            <TableCell key={platform} align='center' sx={{ border: 1 }}>
                                                <TextField
                                                    variant='standard'
                                                    size='small'
                                                    type='number'
                                                    sx={{ width: '60px' }}
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                    }}
                                                    value={stockIn[platform]}
                                                    onChange={(e) => handleChangeStockIn(platform, e.target.value)}
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>

                    {!error ? (
                        <Button variant='contained' onClick={handleStockIn}>
                            입고
                        </Button>
                    ) : (
                        <Button variant='contained' color='error'>
                            오류
                        </Button>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default ModalStock;

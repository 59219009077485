import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    // ToggleButtonGroup,
    // ToggleButton,
    FormControl,
    TextField,
    Button,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { encodeId } from '../util/stockdata.js';

const ModalStockModify = ({ className, open, onClose, modalBoxStyle, data, stockService, historyService }) => {
    // const [sign, setSign] = useState('+');
    const [fromId, setFromId] = useState();
    const [toId, setToId] = useState();
    const [unit, setUnit] = useState();
    const [platform, setPlatform] = useState('해당없음');

    // const handleChangeSign = (e) => {
    //     setSign(e.target.value);
    //     console.log('부호: ' + e.target.value);
    // };

    useEffect(() => {
        setFromId(data.ids);
        // console.log(fromId);
    }, [data, fromId]);

    useEffect(() => {
        const newId = encodeId(platform, data.product, data.option);
        setToId(newId);
    }, [platform]);

    const handleChangeUnit = (e) => {
        setUnit(e.target.value);
    };

    const handleChangePlatform = (e) => {
        setPlatform(e.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!unit) {
            window.alert('개수를 입력하세요!');
            return;
        }
        if (platform === '해당없음') {
            historyService
                .postHistory(data.product, data.option, unit, data.category, data.platform, platform)
                .catch((error) => console.error(error));
        }
        stockService.minusUnit(fromId, unit).then(() => {
            stockService
                .plusUnit(toId, unit)
                .catch((error) => console.log(error))
                .finally(() => {
                    onClose();
                    window.location.reload();
                });
        });
    };

    return (
        <div>
            <Modal className={className} open={open} onClose={onClose}>
                <Box sx={modalBoxStyle}>
                    <Typography variant='h6'>
                        [{data.platform}] {data.product} {data.option} (현재: <strong>{data.unit}</strong> 개)
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            variant='standard'
                            size='small'
                            type='number'
                            sx={{ width: '80px' }}
                            autoFocus
                            InputProps={{
                                inputProps: { min: 0 },
                            }}
                            onChange={handleChangeUnit}
                            required
                        />
                        <strong>개</strong>
                    </Box>
                    <FormControl sx={{ minWidth: '150px' }} size='small'>
                        <InputLabel id='platform-label'>재고 이동 플랫폼</InputLabel>
                        <Select labelId='platform-label' id='platform' value={platform} onChange={handleChangePlatform}>
                            <MenuItem value='해당없음'>해당없음</MenuItem>
                            <MenuItem value='스마트스토어'>스마트스토어</MenuItem>
                            <MenuItem value='에이블리'>에이블리</MenuItem>
                            <MenuItem value='지그재그'>지그재그</MenuItem>
                            <MenuItem value='쿠팡'>쿠팡</MenuItem>
                            <MenuItem value='톡스토어'>톡스토어</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant='contained' onClick={onSubmit}>
                        확인
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default ModalStockModify;

export default class HistoryService {
    constructor(http, tokenStorage) {
        this.http = http;
        this.tokenStorage = tokenStorage;
    }

    async getHistory() {
        return this.http.fetch(`/historys/get`, {
            method: 'GET',
            headers: this.getHeaders(),
        });
    }

    async postHistory(product, option, unit, category, fromPlaform, toPlatform) {
        return this.http.fetch(`/historys`, {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify({
                product,
                option,
                unit,
                category,
                fromPlaform,
                toPlatform,
            }),
        });
    }

    getHeaders() {
        const token = this.tokenStorage.getToken();
        return {
            Authorization: `Bearer ${token}`,
        };
    }
}

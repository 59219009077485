import React, { useState, useEffect } from "react";
import StockCurrent from "../components/StockCurrent";
import StockStat from "../components/SalesStat";
import OrderAlert from "../components/OrderAlert";

const Admin = ({ stockService, historyService, orderService }) => {
	const [callout, setCallout] = useState(false);
	const [orderData, setOrderData] = useState({});
	const [orderDataOfNaver, setOrderDataOfNaver] = useState({});
	const [orderAvgData, setOrderAvgData] = useState({}); // (product+option):totalUnit/7 저장
	const [stockData, setStockData] = useState({}); // (product+option):totalUnit 저장
	const [buyRecommend, setBuyRecommend] = useState({}); //

	// callout 노출 여부를 결정하기 위한 api 호출 - 스마트스토어 판매 기준
	useEffect(() => {
		orderService.getOrdersOf7daysOnNaver().then((data) => {
			setOrderDataOfNaver(data);

			const averages = data.reduce((acc, item) => {
				// 키 생성 (product + option)
				const key = `${item.product} ${item.option}`;
				// totalUnit을 숫자로 변환하고 7로 나눔
				const average = parseInt(item.totalUnit, 10) / 7;
				// 계산된 평균을 객체에 추가
				acc[key] = average;
				return acc;
			}, {});

			setOrderAvgData(averages);
		});

		orderService.getOrdersOf7days().then((data) => {
			setOrderData(data);
		});

		stockService.getStocksBySkuOfNaver().then((data) => {
			const stock = data.reduce((acc, item) => {
				// 키 생성 (product + option)
				const key = `${item.product} ${item.option}`;
				// totalUnit을 정수로 저장
				acc[key] = parseInt(item.totalUnit, 10);
				return acc;
			}, {});

			// 계산된 stock 객체로 상태 업데이트
			setStockData(stock);
		});
	}, []);

	useEffect(() => {
		let recommendations = {};
		for (const key in orderAvgData) {
			if (stockData[key] !== undefined) {
				const stockValue = stockData[key];
				const orderAvgValue = orderAvgData[key];
				if (stockValue < orderAvgValue * 14) {
					const [product, option] = key.split(" ");
					recommendations[key] = {
						product,
						option,
						stockValue,
						orderAvgValue,
					};
				}
			}
		}
		setBuyRecommend(recommendations);
	}, [orderAvgData, stockData]);

	// callout 노출 여부를 결정하기 위한 api 호출 - 전체 판매 기준
	// useEffect(() => {
	//     orderService.getOrdersOf7days().then((data) => {
	//         setOrderData(data);
	//         const averages = data.reduce((acc, item) => {
	//             // 키 생성 (product + option)
	//             const key = `${item.product} ${item.option}`;
	//             // totalUnit을 숫자로 변환하고 7로 나눔
	//             const average = parseInt(item.totalUnit, 10) / 7;
	//             // 계산된 평균을 객체에 추가
	//             acc[key] = average;
	//             return acc;
	//         }, {});
	//         setOrderAvgData(averages);
	//     });

	//     stockService.getStocksBySku().then((data) => {
	//         const stock = data.reduce((acc, item) => {
	//             // 키 생성 (product + option)
	//             const key = `${item.product} ${item.option}`;
	//             // totalUnit을 정수로 저장
	//             acc[key] = parseInt(item.totalUnit, 10);
	//             return acc;
	//         }, {});
	//         // 계산된 stock 객체로 상태 업데이트
	//         setStockData(stock);
	//     });
	// }, []);

	// useEffect(() => {
	//     let recommendations = {};
	//     for (const key in orderAvgData) {
	//         if (stockData[key] !== undefined) {
	//             const stockValue = stockData[key];
	//             const orderAvgValue = orderAvgData[key];
	//             if (stockValue < orderAvgValue * 14) {
	//                 const [product, option] = key.split(' ');
	//                 recommendations[key] = {
	//                     product,
	//                     option,
	//                     stockValue,
	//                     orderAvgValue,
	//                 };
	//             }
	//         }
	//     }
	//     setBuyRecommend(recommendations);
	// }, [orderAvgData, stockData]);

	useEffect(() => {
		// console.log(orderAvgData);
		// console.log(stockData);
		// console.log(orderData);
		if (Object.keys(buyRecommend).length > 0) {
			setCallout(true);
		} else {
			setCallout(false);
		}
	}, [buyRecommend]);

	return (
		<>
			<StockCurrent
				stockService={stockService}
				historyService={historyService}
				orderService={orderService}
			/>
			{callout ? (
				<OrderAlert
					className="callout"
					buyRecommend={buyRecommend}
					orderDataOfNaver={orderDataOfNaver}
					orderData={orderData}
				/>
			) : (
				""
			)}
			<StockStat
				stockService={stockService}
				historyService={historyService}
				orderService={orderService}
			/>
		</>
	);
};

export default Admin;

import React, { useEffect } from "react";
import { AlertTitle, Alert, Box, Typography } from "@mui/material";

const OrderAlert = ({
	className,
	buyRecommend,
	orderDataOfNaver,
	orderData,
}) => {
	const productOrder = ["썸머", "구름"];
	const optionOrder = {
		썸머: ["화이트", "스킨", "블랙", "페일핑크", "스카이블루"],
		구름: ["화이트", "스킨", "블랙"],
	};

	const renderAlerts = () => {
		return productOrder.flatMap((product) => {
			return optionOrder[product].map((option) => {
				const key = `${product} ${option}`;
				const item = buyRecommend[key];
				if (item) {
					const productName =
						product === "썸머"
							? "☀️ 썸머 브이넥 브라렛 1.0 ☀️"
							: "☁️ 구름메쉬 브라렛 ☁️";
					const postposition =
						option === "화이트" ||
						option === "페일핑크" ||
						option == "스카이블루"
							? "를"
							: "을";

					return (
						<AlertTitle
							key={key}
							sx={{
								fontWeight: 900,
								color: "#D74544",
								fontSize: "18px",
							}}
						>
							오늘 [{productName}] [{option}] {postposition} 주문하는 것을
							추천합니다.
						</AlertTitle>
					);
				}
				return null;
			});
		});
	};

	const renderProductStock = () => {
		return productOrder.flatMap((product) => {
			return optionOrder[product].map((option) => {
				const key = `${product} ${option}`;
				const item = orderDataOfNaver.find(
					(prod) => prod.product === product && prod.option === option
				);
				const itemTotal = orderData.find(
					(prod) => prod.product === product && prod.option === option
				);
				const unitsOfNaver = item ? item.totalUnit : "0";
				const unitsOfTotal = itemTotal ? itemTotal.totalUnit : "0";

				const productName =
					product === "썸머" ? "썸머 브이넥 브라렛" : "구름메쉬 브라렛";

				return (
					<Typography key={key} sx={{ fontSize: "15px" }}>
						• {productName} - {option} : {unitsOfNaver} / {unitsOfTotal}
					</Typography>
				);
			});
		});
	};

	return (
		<div className={className}>
			<Alert severity="error">{renderAlerts()}</Alert>
			<Alert variant="outlined" severity="info" sx={{ mt: "10px" }}>
				<Box>
					<Typography sx={{ fontWeight: 900, fontSize: "18px" }}>
						📦 지난 7일간 상품 구매 데이터 (스마트스토어/전체)
					</Typography>
					{renderProductStock()}
				</Box>
			</Alert>
		</div>
	);
};

export default OrderAlert;

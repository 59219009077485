export function parseId(id) {
    const platformCodes = {
        S: '스마트스토어',
        A: '에이블리',
        Z: '지그재그',
        C: '쿠팡',
        T: '톡스토어',
    };
    const productCodes = { S: '썸머', C: '구름' };
    const optionCodes = {
        W: '화이트',
        S: '스킨',
        B: '블랙',
        PP: '페일핑크',
        SB: '스카이블루',
    };

    const platform = platformCodes[id[0]];
    const product = productCodes[id[1]];
    let option = '';
    // 옵션 코드가 두 자리일 수 있으므로 확인
    if (optionCodes[id.substring(2)]) {
        option = optionCodes[id.substring(2)];
    } else {
        option = optionCodes[id[2]] + optionCodes[id[3]];
    }

    return { platform, product, option };
}

export function encodeId(platformName, productName, optionName) {
    const platformCodes = {
        스마트스토어: 'S',
        에이블리: 'A',
        지그재그: 'Z',
        쿠팡: 'C',
        톡스토어: 'T',
    };
    const productCodes = {
        썸머: 'S',
        구름: 'C',
    };
    const optionCodes = {
        화이트: 'W',
        스킨: 'S',
        블랙: 'B',
        페일핑크: 'PP',
        스카이블루: 'SB',
    };

    const platform = platformCodes[platformName];
    const product = productCodes[productName];
    const option = optionCodes[optionName];

    return platform + product + option;
}

export function aggregateData(data) {
    const aggregated = {};
    data.forEach((item) => {
        const { stock_id, unit, platform, product, option } = item;

        if (!aggregated[product]) {
            aggregated[product] = {};
        }
        if (!aggregated[product][option]) {
            aggregated[product][option] = { platforms: {}, total: 0, ids: [] };
        }
        if (!aggregated[product][option].platforms[platform]) {
            aggregated[product][option].platforms[platform] = {
                units: 0,
                ids: [],
            };
        }

        aggregated[product][option].platforms[platform].units += unit;
        aggregated[product][option].total += unit;
        aggregated[product][option].platforms[platform].ids.push(stock_id);
    });

    return aggregated;
}

export const processData = (rawData) => {
    let organizedData = {};
    let totals = platformOrder.reduce((acc, platform) => {
        acc[platform] = { totalUnit: 0, totalPrice: 0 };
        return acc;
    }, {});

    productOrder.forEach((product) => {
        organizedData[product] = {};
        optionOrder.forEach((option) => {
            if (product === '구름' && (option === '페일핑크' || option === '스카이블루')) {
                return;
            }
            organizedData[product][option] = {};
            platformOrder.forEach((platform) => {
                organizedData[product][option][platform] = {
                    totalUnit: 0,
                    totalPrice: 0,
                };
            });
        });
    });

    rawData.forEach(({ platform, product, option, totalUnit, totalPrice }) => {
        if (organizedData[product] && organizedData[product][option] && organizedData[product][option][platform]) {
            organizedData[product][option][platform] = {
                totalUnit: parseInt(totalUnit, 10) || 0, // Handle non-numeric data gracefully // 수정
                totalPrice: parseInt(totalPrice, 10) || 0, // 수정
            };
            totals[platform].totalUnit += organizedData[product][option][platform].totalUnit; // Use parsed values directly // 수정
            totals[platform].totalPrice += organizedData[product][option][platform].totalPrice; // 수정
        }
    });

    return { organizedData, totals };
};

const productOrder = ['썸머', '구름'];
const optionOrder = ['화이트', '스킨', '블랙', '페일핑크', '스카이블루'];
const platformOrder = ['스마트스토어', '에이블리', '지그재그', '쿠팡', '톡스토어'];

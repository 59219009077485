import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ko'; // 한국어 로케일을 추가
import { processData } from '../util/stockdata.js';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
} from '@mui/material';

dayjs.locale('ko');

const platformOrder = ['스마트스토어', '에이블리', '지그재그', '쿠팡', '톡스토어'];

const StockStat = ({ stockService, historyService, orderService }) => {
    const [data, setData] = useState({});
    const [totalsByPlatform, setTotalsByPlatform] = useState({});
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [showStart, setShowStart] = useState('');
    const [showEnd, setShowEnd] = useState('');

    useEffect(() => {
        const start = startDate.format('YYYY-MM-DD');
        const end = endDate.format('YYYY-MM-DD');
        // const start = '2024-01-01';
        // const end = '2024-01-02';
        orderService.getOrders(start, end).then((res) => {
            const { organizedData, totals } = processData(res);
            setData(organizedData);
            setTotalsByPlatform(totals);
        });
    }, [orderService]);

    const handleFilter = () => {
        const start = startDate.format('YYYY-MM-DD');
        const end = endDate.format('YYYY-MM-DD');
        orderService.getOrders(start, end).then((res) => {
            const { organizedData, totals } = processData(res);
            setData(organizedData);
            setTotalsByPlatform(totals);
        });
    };

    const filterDaily = () => {
        const start = dayjs().startOf('day').format('YYYY-MM-DD');
        const end = dayjs().endOf('day').format('YYYY-MM-DD');
        orderService.getOrders(start, end).then((res) => {
            const { organizedData, totals } = processData(res);
            setData(organizedData);
            setTotalsByPlatform(totals);
        });
        // console.log(`시작일: ${start}, 종료일: ${end}`);
        setShowStart(start.split(' ')[0]);
        setShowEnd(end.split(' ')[0]);
    };

    const filterWeekly = () => {
        const start = dayjs().startOf('week').add(1, 'day').startOf('day').format('YYYY-MM-DD'); // 월요일 00:00:00
        const end = dayjs().endOf('week').add(1, 'day').endOf('day').format('YYYY-MM-DD'); // 일요일 23:59:59
        orderService.getOrders(start, end).then((res) => {
            const { organizedData, totals } = processData(res);
            setData(organizedData);
            setTotalsByPlatform(totals);
        });
        // console.log(`시작일: ${start}, 종료일: ${end}`);
        setShowStart(start.split(' ')[0]);
        setShowEnd(end.split(' ')[0]);
    };

    const filterMonthly = () => {
        const start = dayjs().startOf('month').startOf('day').format('YYYY-MM-DD');
        const end = dayjs().endOf('month').endOf('day').format('YYYY-MM-DD');
        orderService.getOrders(start, end).then((res) => {
            const { organizedData, totals } = processData(res);
            setData(organizedData);
            setTotalsByPlatform(totals);
        });
        // console.log(`시작일: ${start}, 종료일: ${end}`);
        setShowStart(start.split(' ')[0]);
        setShowEnd(end.split(' ')[0]);
    };

    return (
        <div>
            <Typography
                sx={{
                    textAlign: 'center',
                    mt: 5,
                    mb: 3,
                    fontSize: '2rem',
                    fontWeight: '900',
                }}
            >
                판매 통계
            </Typography>
            <Typography sx={{ fontSize: '1rem', color: '#012764', ml: '15px' }}>
                {showStart} ~ {showEnd}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ mb: '20px', alignSelf: 'flex-end' }}>
                    <Button variant='contained' sx={{ mr: '10px' }} onClick={filterDaily}>
                        일간
                    </Button>
                    <Button variant='contained' sx={{ mr: '10px' }} onClick={filterWeekly}>
                        주간
                    </Button>
                    <Button variant='contained' sx={{ mr: '10px' }} onClick={filterMonthly}>
                        월간
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        p: '0 0 20px 0',
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateFormats={{ monthShort: `M` }}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                value={startDate}
                                onChange={(newDate) => {
                                    setStartDate(newDate);
                                    // console.log(newDate.format('YYYY-MM-DD'));
                                }}
                                textField={(params) => <TextField {...params} />}
                                showDaysOutsideCurrentMonth
                                format='YYYY-MM-DD'
                            />
                            <DatePicker
                                value={endDate}
                                onChange={(newDate) => {
                                    setEndDate(newDate);
                                    // console.log(newDate.format('YYYY-MM-DD'));
                                }}
                                textField={(params) => <TextField {...params} />}
                                showDaysOutsideCurrentMonth
                                format='YYYY-MM-DD'
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    <Button
                        variant='contained'
                        sx={{
                            maxHeight: '40px',
                            ml: '10px',
                            alignSelf: 'flex-end',
                        }}
                        onClick={handleFilter}
                    >
                        필터 적용
                    </Button>
                </Box>
            </Box>
            <TableContainer component={Paper} sx={{ border: 1 }}>
                <Table aria-label='simple table' sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' sx={{ border: 1 }}>
                                상품명
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                옵션
                            </TableCell>
                            {platformOrder.map((platform) => (
                                <TableCell key={platform} align='center' sx={{ border: 1 }}>
                                    {platform}
                                </TableCell>
                            ))}
                            <TableCell align='center' sx={{ border: 1, fontWeight: 'bold' }}>
                                총 판매량
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1, fontWeight: 'bold' }}>
                                총 판매액
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(data).map((product) =>
                            Object.keys(data[product]).map((option, index) => (
                                <TableRow key={`${product}-${option}`}>
                                    {index === 0 && (
                                        <TableCell
                                            rowSpan={Object.keys(data[product]).length}
                                            align='center'
                                            sx={{ border: 1 }}
                                        >
                                            {product}
                                        </TableCell>
                                    )}
                                    <TableCell align='center' sx={{ border: 1 }}>
                                        {option}
                                    </TableCell>
                                    {platformOrder.map((platform) => (
                                        <TableCell
                                            key={`${product}-${option}-${platform}`}
                                            align='center'
                                            sx={{ border: 1 }}
                                        >
                                            {data[product][option][platform]
                                                ? data[product][option][platform].totalUnit
                                                : '-'}
                                        </TableCell>
                                    ))}
                                    <TableCell align='center' sx={{ border: 1 }}>
                                        {Object.values(data[product][option]).reduce(
                                            (sum, current) => sum + parseInt(current.totalUnit),
                                            0
                                        )}
                                    </TableCell>
                                    <TableCell align='center' sx={{ border: 1 }}>
                                        ￦
                                        {Object.values(data[product][option])
                                            .reduce((sum, current) => sum + parseInt(current.totalPrice), 0)
                                            .toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2} align='center' sx={{ border: 1, fontWeight: 'bold' }}>
                                총 판매량
                            </TableCell>
                            {platformOrder.map((platform) => (
                                <TableCell key={`total-unit-${platform}`} align='center' sx={{ border: 1 }}>
                                    {totalsByPlatform[platform]?.totalUnit || 0}
                                </TableCell>
                            ))}
                            <TableCell align='center' sx={{ border: 1 }} colSpan={2}>
                                {platformOrder.reduce(
                                    (sum, platform) => sum + (totalsByPlatform[platform]?.totalUnit || 0),
                                    0
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} align='center' sx={{ border: 1, fontWeight: 'bold' }}>
                                총 판매액
                            </TableCell>
                            {platformOrder.map((platform) => (
                                <TableCell key={`total-price-${platform}`} align='center' sx={{ border: 1 }}>
                                    ￦{totalsByPlatform[platform]?.totalPrice.toLocaleString() || 0}
                                </TableCell>
                            ))}
                            <TableCell align='center' sx={{ border: 1 }} colSpan={2}>
                                ￦
                                {platformOrder
                                    .reduce((sum, platform) => sum + (totalsByPlatform[platform]?.totalPrice || 0), 0)
                                    .toLocaleString()}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default StockStat;

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import HttpClient from './network/http';
import TokenStorage from './db/token';
import { AuthProvider } from './context/AuthContext';
import { AuthErrorEventBus } from './context/AuthContext';
import App from './App';
import AuthService from './service/auth';
import StockService from './service/stock';
import HistoryService from './service/history';
import OrderService from './service/order';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00398F',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    padding: '0px 5px', // 전체 TextField 패딩 조정
                    '& .MuiInputBase-input': {
                        padding: '10px 10px', // 입력 부분 패딩 조정
                    },
                    width: '70px',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#D8E8FF',
                },
            },
        },
    },
});

export const API_ENDPOINT_PROD = `https://api-admin.poola.co.kr`;
export const API_ENDPOINT_LOCAL = 'http://localhost:8080';
export const API_ENDPOINT =
    window.location.hostname === 'localhost'
        ? API_ENDPOINT_LOCAL
        : API_ENDPOINT_PROD;

const baseURL = API_ENDPOINT;
// const baseURL = process.env.REACT_APP_BASE_URL;
// console.log('baseURL: ', baseURL);
const httpClient = new HttpClient(baseURL);
const tokenStorage = new TokenStorage();
const authErrorEventBus = new AuthErrorEventBus();
const authService = new AuthService(httpClient, tokenStorage);
const stockService = new StockService(httpClient, tokenStorage);
const historyService = new HistoryService(httpClient, tokenStorage);
const orderService = new OrderService(httpClient, tokenStorage);

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <AuthProvider
                    authService={authService}
                    authErrorEventBus={authErrorEventBus}
                >
                    <App
                        stockService={stockService}
                        historyService={historyService}
                        orderService={orderService}
                    />
                </AuthProvider>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

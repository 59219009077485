import React, { useState, useEffect } from 'react';
import HistoryData from '../components/HistoryData';
import {
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';

const History = ({ historyService }) => {
    const [recents, setRecents] = useState([]);

    useEffect(() => {
        historyService.getHistory().then((res) => {
            setRecents(res);
        });
    }, []);

    return (
        <>
            <Typography
                sx={{
                    textAlign: 'center',
                    mb: 3,
                    fontSize: '2rem',
                    fontWeight: '900',
                }}
            >
                재고 변동 히스토리
            </Typography>
            <TableContainer component={Paper} sx={{ border: 1 }}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' sx={{ border: 1 }}>
                                일자
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                상품
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                옵션
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                개수
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                구분
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                From 플랫폼
                            </TableCell>
                            <TableCell align='center' sx={{ border: 1 }}>
                                To 플랫폼
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recents.map((recent) => (
                            <HistoryData history={recent} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default History;
